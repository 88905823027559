import React, { useEffect, useRef, useState } from "react";
import BreadcrumbHeader from "../Component/BreadcrumbHeader";
import { Button, Card, Flex, Input, Modal, Space, Table, message } from "antd";
import constants from "../../Constants/constants";
import { fetchStaffApi } from "../../Services/Api";
import AdvisorTimings from "./AdvisorTimings";
import ReactToPrint from "react-to-print";
import dayjs from "dayjs";
import { CustomSortIcon, HighlightedText, getNextAndPreviousButtons, handleTableChange, performSearch, showTotalItems } from "../../GlobalFunctions/GlobalFunctions";
import { CheckOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { COOKIE, getCookie } from "../../Services/Cookie";
import Print from "../Component/PrintComponent/Print";

const AdvisorSignup = () => {
  const pageTitle = constants.AdvisorSignup;
  const breadcrumbItems = [
    { label: constants.AdvisorSignup, active: true },
  ];
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [listData, setListData] = useState([]);
  const [checkedRecord, setCheckedRecord] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [formType, setFormType] = useState(null);
  const [selectedRow, setSelectedRow] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [sortedTableData, setSortedTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableSorters, setTableSorters] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const facultyId = getCookie(COOKIE.UserId);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const toggleModal = (formType) => {
    if (formType === 1) {
      setIsModalVisible(true);
      setFormType(1);
    } else if (formType === 0) {
      setFormType(0);
      const selectedRowId = checkedRecord;
      const selectedRow = listData?.find((row) => row.id === selectedRowId);
      setSelectedRow(selectedRow);
      setIsModalVisible(true);
    }
  };

  const tableRef = useRef();

  useEffect(() => {
    advisorSignupList();
  }, []);
  const advisorSignupList = () => {
    const modifyData = (data) => {
      return data?.map((item) => {
        //MODIFY TIMINGS FIELD
        const [startTime, endTime] = item?.timings.split(" - ");
        const formattedStartTime = dayjs(startTime, "HH:mm").format("hh:mm A");
        const formattedEndTime = dayjs(endTime, "HH:mm").format("hh:mm A");

        return {
          ...item,
          sent_text: item.sent === 'f' ? constants.No : constants.Yes,
          advising_date_text: dayjs(item.advising_date).format('MM/DD/YYYY'),
          timings_text: `${formattedStartTime} - ${formattedEndTime}`
        }
      })
    }

    let payload = {
      faculty_id: facultyId,
    };
    fetchStaffApi("/get-my-advising-chart", "POST", payload).then((res) => {
      let data = res?.data?.length > 0 ? modifyData(res?.data) : []
      setListData(data);
      setFilteredData(data);
      onTableChange(currentPage, null, tableSorters, data, false)
    });
  };
  const AdvisorsStudentCols = [
    {
      title: `${constants.Sent}`,
      columnName: constants.Sent,
      dataIndex: "sent_text",
      key: "sent",
      width: "6%",
      render: (_, record) =>
        record?.sent === "t" && (
          <CheckOutlined className="flex-center success-icon faculty-form-item" />
        ), 
    },
    {
      title: `${constants.Date}`,
      columnName: constants.Date,
      dataIndex: "advising_date_text",
      key: "advising_date_text",
      sorter: {
        compare: (a, b) =>
          dayjs(a.advising_date).isBefore(dayjs(b.advising_date)) ? -1 : 1,
      },
      sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
      render: (date) => (
        <HighlightedText
          text={dayjs(date).format(constants.DateFormat)}
          highlight={searchValue}
        />
      ),
      showSorterTooltip: { show: true, placement: 'bottom' },
    },
    {
      title: `${constants.Timings}`,
      columnName: constants.Timings,
      dataIndex: "timings_text",
      key: "timings_text",
      sorter: {
        compare: (a, b) => {
          const [startTime,] = a.timings.split(" - ");
          const [nextStartTime,] = b.timings.split(" - ");
          return dayjs(startTime, "HH:mm").diff(dayjs(nextStartTime, "HH:mm"));
        },
      },
      render: (_, { timings_text }) => <HighlightedText text={timings_text} highlight={searchValue}/>,
      sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
      showSorterTooltip: { show: true, placement: 'bottom' },
    },
    {
      title: `${constants.Spots}`,
      columnName: constants.Spots,
      dataIndex: "spots",
      key: "spots",
      width: "15%",
      sorter: {
        compare: (a, b) => a.spots - b.spots,
      },
      render: (_, record) => (
        <span className="numbers-alignment">
          <HighlightedText text={record.spots} highlight={searchValue} />
        </span>
      ),
      sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
      showSorterTooltip: { show: true, placement: 'bottom' },
    },
    {
      title: `${constants.StudentsEnrolled}`,
      columnName: constants.StudentsEnrolled,
      dataIndex: "student_names",
      key: "student_names",
      sorter: {
        compare: (a, b) => (a.student_names || "").localeCompare(b.student_names || ""),
      },
      sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
      render: (student_names) => (
        <HighlightedText text={student_names} highlight={searchValue} />
      ),
      showSorterTooltip: { show: true, placement: 'bottom' },
    },
  ];

  const handleCheckboxChange = (record) => {
    const key = record.id;
    if (checkedRecord === key) {
      setCheckedRecord(null);
      setIsDisabled(true);
    } else {
      setCheckedRecord(key);
      setIsDisabled(false);
    }
  };

  const rowSelection = {
    selectedRowKeys: [checkedRecord],
    onSelect: handleCheckboxChange,
    hideSelectAll: true,
    type: "checkbox",
  };

  const handleRowClick = (record) => {
    handleCheckboxChange(record);
  };

  const isSendButtonDisabled = () => {
    if (checkedRecord !== null) {
      const selectedRow = listData?.find((row) => row.id === checkedRecord);
      return (
        selectedRow &&
        (dayjs(selectedRow.advising_date).isBefore(dayjs(), "day") ||
          (dayjs(selectedRow.advising_date).isSame(dayjs(), "day") &&
            dayjs(selectedRow.timings.split(" - ")[1], "HH:mm").isBefore(
              dayjs(),
              "minute"
            )) ||
          selectedRow.sent === "t")
      );
    } else {
      return listData?.every(
        (row) =>
          dayjs(row.advising_date).isBefore(dayjs(), "day") ||
          (dayjs(row.advising_date).isSame(dayjs(), "day") &&
            dayjs(row.timings.split(" - ")[1], "HH:mm").isBefore(
              dayjs(),
              "minute"
            )) ||
          row.sent === "t"
      );
    }
  };

  const onSearch = (event) => {
    const search = event.target.value;
    const fieldNames = ["advising_date", "timings", "spots", "student_names"];
    const searchedValue = performSearch(search, filteredData, fieldNames);
    setListData(searchedValue);
    setSortedTableData(searchedValue);
    setSearchValue(search);
  };

  const handleDelete = () => {
    fetchStaffApi(`/delete-advisor-timings/${checkedRecord}`, "DELETE")
      .then((res) => {
        if (res.code === "200") {
          message.success(constants.DeleteMessage);
          advisorSignupList();
          setCheckedRecord(null);
          setIsDisabled(true);
        } else {
          message.error(constants.ErrorMessage);
        }
      })
      .catch(() => {
        message.error(constants.ErrorMessage);
      });
    setIsModalOpen(false);
  };

  const isEditButtonDisabled = () => {
    if (checkedRecord !== null) {
      const selectedRow = listData?.find((row) => row.id === checkedRecord);
      return (
        selectedRow &&
        (dayjs(selectedRow.advising_date).isBefore(dayjs(), "day") ||
          (dayjs(selectedRow.advising_date).isSame(dayjs(), "day") &&
            dayjs(selectedRow.timings.split(" - ")[1], "HH:mm").isBefore(
              dayjs(),
              "minute"
            )))
      );
    } else {
      return false;
    }
  };  

  const advisingStudentIds = [];

  if (listData?.length > 0) {
    for (let i = 0; i < listData.length; i++) {
      advisingStudentIds.push(listData[i].id);
    }
  }

  const handleSend = () => {
    const validRecords = listData?.filter(
      (item) =>
        !(
          dayjs(item.advising_date).isBefore(dayjs(), "day") ||
          (dayjs(item.advising_date).isSame(dayjs(), "day") &&
            dayjs(item.timings.split(" - ")[1], "HH:mm").isBefore(
              dayjs(),
              "minute"
            )) ||
          item.sent === "t"
        )
    );
    const validRecordIds = validRecords.map((item) => item.id);
    if (checkedRecord) {
      let payload = {
        id: [checkedRecord],
      };
      fetchStaffApi("/send-advisor-timings", "POST", payload)
        .then((res) => {
          if (res.code === "200") {
            message.success(constants.SentMessage);
            advisorSignupList();
          } else {
            message.error(constants.ErrorMessage);
          }
        })
        .catch(() => {
          message.error(constants.ErrorMessage);
        });
    } else {
      let payload = {
        id: validRecordIds,
      };
      fetchStaffApi("/send-advisor-timings", "POST", payload)
        .then((res) => {
          if (res.code === "200") {
            message.success(constants.SentMessage);
            advisorSignupList();
          } else {
            message.error(constants.ErrorMessage);
          }
        })
        .catch(() => {
          message.error(constants.ErrorMessage);
        });
    }
  };

  function onTableChange(current, filters, sorter, currentDataSource, fromTableChange) {
		let result = handleTableChange(current, filters, sorter, currentDataSource, fromTableChange)
		setCurrentPage(result?.current)
		setTableSorters(result?.sorter)
		setSortedTableData(result?.data)
	}

  const onCancelPopUp =()=> {
    setIsModalVisible(false);
    advisorSignupList();
  };

  return (
    <>
      <BreadcrumbHeader
        pageTitle={pageTitle}
        breadcrumbItems={breadcrumbItems}
      />
      <div>
        <Card className="container-border-custom">
          <div className="flex justify-between flex-wrap gap-2">
            <Space wrap>
              <Button
                className="text-capitalize btn-info"
                disabled={!isDisabled}
                onClick={() => toggleModal(1)}
              >
                {constants.Add}
              </Button>
              <Button
                className=" text-capitalize btn-info"
                disabled={isDisabled || isEditButtonDisabled()}
                onClick={() => toggleModal(0)}
              >
                {constants.Edit}
              </Button>
              <Button
                className=" text-capitalize btn-info"
                onClick={showModal}
                disabled={isDisabled}
              >
                {constants.Delete}
              </Button>
              <Button
                className=" text-capitalize btn-info"
                onClick={handleSend}
                disabled={isSendButtonDisabled()}
              >
                {constants.Send}
              </Button>
              <Print
                columns={AdvisorsStudentCols}
                data={sortedTableData}
                currentPage={currentPage}
                disabled={sortedTableData?.length === 0}
                headerTitle={constants.AdvisorSignup}
                type='json'
                key='print'
              />
            </Space>
            <Input
              className="w-48"
              placeholder={constants.Search}
              onChange={onSearch}
              value={searchValue}
            />
          </div>
          {listData?.length > 0 && listData?.some(
            (item) =>
              !(dayjs(item.advising_date).isBefore(dayjs(), "day") ||
              (dayjs(item.advising_date).isSame(dayjs(), "day") &&
                dayjs(item.timings.split(" - ")[1], "HH:mm").isBefore(
                  dayjs(),
                  "minute"
                )) ||
              item.sent === "t")
          ) && (
            <div>
              <InfoCircleOutlined className="input-info info-message" />{" "}
              {constants.SendMessage}
            </div>
          )}
          <div className="mt-2" ref={tableRef}>
            <Table
              className="records-table overflow-auto data_table"
              rowSelectedBg="#e6f4ff"
              columns={AdvisorsStudentCols}
              dataSource={listData}
              rowKey={(record) => record.id}
              bordered
              size="small"
              scroll={{ x: 768 , y: 'calc(100vh - 350px)'}}
              rowSelection={rowSelection}
              pagination={{
                current: currentPage,
                pageSize: constants.TablePageSize,
                showTotal: (total, range) =>
                  showTotalItems(total, range, listData),
                itemRender: getNextAndPreviousButtons,
              }}
              searchableProps={{ fuzzySearch: true }}
              onChange={({ current }, filters, sorter, { currentDataSource }) => onTableChange(current, filters, sorter, currentDataSource, true)}
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
              })}
            />
          </div>
          <AdvisorTimings
            visible={isModalVisible}
            onCancel={onCancelPopUp}
            onList={advisorSignupList}
            formType={formType}
            selectedRowKeys={checkedRecord}
            selectedRow={selectedRow}
          />
        </Card>
        <Modal
          title={constants.Confirm}
          open={isModalOpen}
          className="records-popup"
          footer={null}
          onOk={handleDelete}
          onCancel={handleCancel}
        >
          {checkedRecord &&
          listData?.some(
            (item) => item.id === checkedRecord && item.student_names !== ""
          ) ? (
            <div>{constants.DeleteUserMessage}</div>
          ) : (
            <div>{constants.RequestMessage}</div>
          )}
          <Flex
            gap="small"
            align="flex-end"
            className="action-button-container info-message"
          >
            <Flex gap="small" wrap="wrap">
              <Button
                type="primary"
                className="action-btn"
                danger
                onClick={handleCancel}
              >
                {constants.Cancel}
              </Button>
              <Button
                type="primary"
                className="action-btn"
                htmlType="submit"
                onClick={handleDelete}
              >
                {constants.Delete}
              </Button>
            </Flex>
          </Flex>
        </Modal>
      </div>
    </>
  );
};

export default AdvisorSignup;
