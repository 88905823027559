import React, { useState } from "react";
import constants from "../../Constants/constants";
import BreadcrumbHeader from "../Component/BreadcrumbHeader";
import { fetchApi } from "../../Services/Api";
import { Alert, Button, Card, Col, Flex, Modal, Row, message } from "antd";
import { WarningFilled, CloseCircleFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import { useNavigate, useOutletContext } from "react-router-dom";
import StudentMentorRequest from "../StudentSection/StudentMentorRequest";
import { notificationHandler, removeExtraSpaces } from '../../GlobalFunctions/GlobalFunctions';
import { COOKIE, createCookie, getCookie } from "../../Services/Cookie";

const Notifications = () => {
  const pageTitle = constants.Notifications;
  const breadcrumbItems = [
    { label: constants.Notifications, active: true },
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNotificationId, setSelectedNotificationId] = useState(null);
  const [mentorRequestModalOpen, setMentorRequestModalOpen] = useState(false);
  const [data, updateNotificationData] = useOutletContext();
  const userRoleId = getCookie(COOKIE.Role);
  const userId = getCookie(COOKIE.UserId);
  const isStudent = userRoleId === '0'
  const navigate = useNavigate();

  const showModal = (id, url) => {
    setSelectedNotificationId(id);
    if (url === "student-mentor-request") {
      setMentorRequestModalOpen(true);
    } else {
      setIsModalOpen(true);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setMentorRequestModalOpen(false);
  };

  const handleClose = () => {
    if (selectedNotificationId) {
      const payload = {
        id: selectedNotificationId,
      };

      fetchApi("/notification-set-viewed", "POST", payload)
        .then((res) => {
          if (res.code === "200") {
            updateNotificationData();
            setIsModalOpen(false);
            setMentorRequestModalOpen(false);
            setSelectedNotificationId(null);
          } else {
            message.error(constants.ErrorMessage)
          }
        })
        .catch((error) => {
          message.error(constants.ErrorMessage)
        });
    }
  };

  const handleNotificationClick = (notif) => {
    if(notif?.faculty_id) createCookie("facultyId", notif?.faculty_id, null, "/");
    notificationHandler(
      notif,
      navigate,
      { 
        isStudent,
      }
    )
  }

  const renderMessage = (item) => (
    <Row>
      <Col span={12}>
        {item.notification_url ? (
          <span
            onClick={() => handleNotificationClick(item)}
            style={{ cursor: "pointer" }}
          >
            <span className="noti-mb-0" dangerouslySetInnerHTML={{ __html: removeExtraSpaces(item.notification_text) }}></span>
          </span>
        ) : (
          <span className="noti-mb-0" dangerouslySetInnerHTML={{ __html: removeExtraSpaces(item.notification_text) }}></span>
        )}
      </Col>
      <Col span={12}>
        <Flex justify="end">
          {dayjs(item.dt_created).format("MMM DD, YYYY [at] h:mm A")}
        </Flex>
      </Col>
    </Row>
  );
  
  const renderCloseIcon = (item) => (
    !item.notification_url && (
      <span
        className="close-icon form-text-deco fs-7"
        onClick={(e) => {
          e.stopPropagation();
          showModal(item.id);
        }}
      >
        <CloseCircleFilled />
      </span>
    )
  );

  const handleSave = (value) => { 
    const payload = {
      student_id: userId,
      value: value,
      notification_id: selectedNotificationId,
    };
    fetchApi("/student/save-mentor-request-status", "POST", payload)
      .then((res) => {
        if (res.code === "200") {
          updateNotificationData();
          message.success(constants.ThankYouResponse);
          navigate(`/student-dashboard`);
        } else {
          message.error(constants.ErrorMessage);
        }
      })
      .catch(() => {
        message.error(constants.ErrorMessage);
      });
  };

  return (
    <>
      <BreadcrumbHeader
        pageTitle={pageTitle}
        breadcrumbItems={breadcrumbItems}
      />
      <div>
        {data?.length > 0 ? (
          <div>
            {data?.map((item) => (
              <Alert
                className="nofication-align"
                key={item.id}
                showIcon
                icon={<WarningFilled />}
                type={
                  item.priority === "1"
                    ? "error"
                    : item.priority === "2"
                    ? "info"
                    : "warning"
                }
                message={renderMessage(item)}
                closeIcon={renderCloseIcon(item)}
              />
            ))}
          </div>
        ) : (
          <div>
            <Card className="container-border-custom">
              <div className="text-center">{constants.NoNotificationsMessage}</div>
            </Card>
          </div>
        )}
        <Modal
          title="Confirmation"
          open={isModalOpen}
          onOk={handleClose}
          onCancel={handleCancel}
          className="records-popup"
          footer={null}
        >
          <p>{constants.RemoveNotificationMessage}</p>
          <Flex
            gap="small"
            align="flex-end"
            className="action-button-container"
          >
            <Flex gap="small" wrap="wrap">
              <Button
                type="primary"
                className="action-btn"
                danger
                onClick={handleCancel}
              >
                {constants.Cancel}
              </Button>
              <Button
                type="primary"
                className="action-btn"
                htmlType="submit"
                onClick={handleClose}
              >
                {constants.RemoveButtonText}
              </Button>
            </Flex>
          </Flex>
        </Modal>
        {
          mentorRequestModalOpen && 
          <StudentMentorRequest 
            open={mentorRequestModalOpen}
            closeModal={handleClose}
            handleSave={(e) => handleSave(e)}
          />
        }
      </div>
    </>
  );
};

export default Notifications;
