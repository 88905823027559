import { faArrowUpWideShort,faArrowDownWideShort,faRightLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { message, Button , Modal, Col} from 'antd';
import dayjs from 'dayjs'
import constants from '../Constants/constants';
import { fetchStaffApi } from '../Services/Api/index'
import DOMPurify from 'dompurify';
import { encodeURI } from "js-base64";

//fuction for current start date and end date.
export const getCurrentDayStartAndEnd = () => {
	// Get the current date and time
	const currentDate = dayjs();
	// Get the start of the day (00:00:00)
	const startOfDay = currentDate.startOf('day');
	// Get the end of the day (23:59:59)
	const endOfDay = currentDate.endOf('day');
	return {
		start: startOfDay.format('MMM D, YYYY h:mm A'),  // Format the start time
		end: endOfDay.format('MMM D, YYYY h:mm A')       // Format the end time
	};
};
//fuction to performSearch.
export const performSearch = (search, originalData, fieldNames,isArray) => {
	if (!search) {
		return originalData;
	}
	// Normalize the search string by replacing multiple spaces with a single space
	const normalizedSearch = search?.toLowerCase()?.replace(/\s+/g, ' ')?.trim();

	return originalData?.filter((dataItem) =>
		fieldNames.some((fieldName) => {
			// Normalize the data item's field value by replacing multiple spaces with a single space
			const fieldValue = (!Array.isArray(dataItem?.[fieldName]))? dataItem?.[fieldName]?.toLowerCase().replace(/\s+/g, ' ').trim():"";
			return fieldValue?.includes(normalizedSearch)||Array.isArray(dataItem?.[fieldName])&&searchNestedData(dataItem?.[fieldName], search);
		})
	);
}
  // Function to recursively search within nested data
  const searchNestedData = (data, text) => {
    if (!Array.isArray(data)) return false;

    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (typeof item === 'object') {
        const values = Object.values(item);
        for (let j = 0; j < values.length; j++) {
          const value = values[j];
          if (typeof value === 'string' && value.toLowerCase().includes(text.toLowerCase())) {
            return true;
          }
          if (Array.isArray(value) && searchNestedData(value, text)) {
            return true;
          }
        }
      }
    }
		return false;
	}
export const tableHeaderTitle = (title) => {
	return <span className='fw-bold'>{title}</span>
}
export const getModal = (title,content,modalVisible,closeModal,setterFunc,setterKeysFunc) => {
	return(
		<Modal
			open={modalVisible}
			onCancel={() => { closeModal(setterFunc); setterKeysFunc() }}
			title={title}
			className="!top-5"
			footer={null}
			width={815}
		>
			{content}
		</Modal>
	)
}
//DisplayModal onclick
export const showModal = (setShowModalFunc) => {
	setShowModalFunc(true);
}
//CloseModal onclick
export const closeModal = (setShowModalFunc) => {
	setShowModalFunc(false);
};
export const getGpaLevelsAndModifyDataFormat = (setterFunc) => {
	fetchStaffApi(`/get-gpa-levels`, 'GET').then((res) => {
		if (res?.data) {
			const groupedData = {};
			// Group data by 'year_in_program'
			res?.data.forEach(item => {
				const key = item.year_in_program;
				if (!groupedData[key]) {
					groupedData[key] = {
						key,
						year_in_program: `${item.year_in_program}`,
						fall: 'Fall',
						spring: 'Spring',
						summer: 'Summer',
						fallGpa: null,
						springGpa: null,
						summerGpa: null,
					};
				}
				// Populate GPA values based on 'semester_type'
				switch (item.semester_type) {
					case '1':
						groupedData[key].fallGpa = item.gpa;
						break;
					case '2':
						groupedData[key].springGpa = item.gpa;
						break;
					case '3':
						groupedData[key].summerGpa = item.gpa;
						break;
					default:
						break;
				}
			});
			const formattedData = Object.values(groupedData);
			setterFunc(formattedData);
		} else {
			setterFunc([]);
		}
	});
}

//function to get status name using id
export const getStatusName = (status_id) => {
  const mapStatus = {
    "1": constants.Dismissed,
    "2": constants.Probation,
    "3": constants.LeftProgram,
    "4": constants.Break,
    "5": constants.InActive,
    "6": constants.SlpMajor,
    "7": constants.Active,
    "8": constants.OffProbation,
    "9": constants.Undeclared,
  };
  return mapStatus[status_id];
};

export const HighlightedText = ({ text, highlight }) => {
	if (!highlight?.trim()) {
		return <span>{text}</span>;
	}

	const parts = text?.split(new RegExp(`(${highlight})`, 'gi'));
	return (
		<span>
			{parts?.map((part, i) => (
				<span key={i} className={part?.toLowerCase() === highlight?.toLowerCase() ?
					'highlight' : ''}>
					{part}
				</span>
			))}
		</span>
	);
};

export const HighlightedHTML = ({ html, highlight }) => {
  if (!highlight?.trim()) {
    return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}></div>;
  }

  const sanitizedHTML = DOMPurify.sanitize(html);

  // Create a temporary container to hold the HTML content
  const container = document.createElement('div');
  container.innerHTML = sanitizedHTML;

  // Recursive function to traverse and highlight text nodes
  const highlightTextNodes = (node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      const regex = new RegExp(`(${highlight})`, 'gi');
      const parts = node.textContent.split(regex);

      if (parts.length > 1) {
        const fragment = document.createDocumentFragment();
        parts.forEach((part) => {
          if (regex.test(part)) {
            const span = document.createElement('span');
            span.className = 'highlight';
            span.textContent = part;
            fragment.appendChild(span);
          } else {
            fragment.appendChild(document.createTextNode(part));
          }
        });
        node.replaceWith(fragment);
      }
    } else {
      Array.from(node.childNodes).forEach(highlightTextNodes);
    }
  };

  // Apply highlighting to all child nodes
  Array.from(container.childNodes).forEach(highlightTextNodes);

  return <div dangerouslySetInnerHTML={{ __html: container.innerHTML }}></div>;
};

//function related on keypress input || Allow only numbers (0-9)
export const handleKeyPressHandler = (event, maxLength) => {
	const charCode = event.which || event.keyCode;
	// Allow only numbers (0-9)
	if (charCode < 48 || charCode > 57) {
		event.preventDefault();
	}
	// Limit the input length to the specified maximum
	const currentValue = event.target.value;
	if(!maxLength) return
	if(currentValue.length >= maxLength) {
		event.preventDefault();
	}
};

//fuction to allow only alphabets.
export const allowAlphabets = (event) => {
	// Allow only alphabets, hyphen, underscore, space, or single quote
	const regex = /^[A-Za-z\-_' ]$/i;
	if (!regex.test(event.key)) {
		event.preventDefault();
	}
};

// Custom validation function for the rule
export const generateNumberValidator = (maxLength, message) => (_, value) => {
	if (!value) {
		return Promise.resolve();
	}
	if (value.length >= maxLength) {
		return Promise.resolve();
	} else {
		return Promise.reject(new Error(message));
	}
};

//Tiny MCE text editor validator
export const handleValidateTextEditor = (rule, value, callback, field, form) => { 
	try {
		if(!form.getFieldValue(field)) {
			throw new Error(constants.FieldRequired);
		}
		callback();
	} catch (err) {
		callback(err);
	}
};

//Formating phone number to add hyphens
export const phoneNumberFormatter = (value) => {
	// Remove non-numeric characters
	const numericValue = value?.replace(/\D/g, '');
	// Add hyphens after every 3 and 6 numbers
	// preg_replace("/^(\d{3})(\d{3})(\d{4})$/", "$1-$2-$3"
	const formattedValue = numericValue?.replace(/(\d{3})(\d{3})?(\d{0,4})?/, (_, first, second, third) => {
		let result = `${first}`;
		if (second) result += `-${second}`;
		if (third) result += `-${third}`;
		return result;
	});
	return formattedValue;
};

export const beforeProfileUpload = (file) => {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
	if (!isJpgOrPng) {
		message.error(constants.InvalidFileFormat);
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('Maximum file size accepted is 2MB.');
	}
	return isJpgOrPng && isLt2M;
};

export const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	return reader.readAsDataURL(img);
};

export const beforeUploadCheckFileType = (file,showMessage=true) => {
	const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
	if (!allowedTypes.includes(file.type)) {
		if(showMessage){
			message.error(constants.InvalidFileFormat);
		}
		return false;
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		if(showMessage){
			message.error(constants.MaxFileSizeMessage);
		}
		return false;
	}
	return true;
};

export const CustomSortIcon = ({ sortOrder }) => {
	if (sortOrder === 'ascend') {
	  return <FontAwesomeIcon icon={faArrowUpWideShort} />;
	} else if (sortOrder === 'descend') {
	  return <FontAwesomeIcon icon={faArrowDownWideShort} />;
	}
	return <FontAwesomeIcon icon={faRightLeft} rotation={90} />;
  };

export const getNextAndPreviousButtons = (current, type, originalElement) => {
	if (type === 'prev') {
		return <Button className='prevBtn' size='small'>{constants.Previous}</Button>;
	} else if (type === 'next') {
		return <Button className='nextBtn' size='small'>{constants.Next}</Button>;
	}
	return originalElement;
};
export const showTotalItems = (total, range, arr) => {
	return <span className="fw-medium text-sm">{`Showing ${range[0]}-${range[1]} of ${arr.length} entries`}</span>
};

export function myTrim(myString) {
	if (!myString) return '';
	return myString.replace(/^\s+|\s+$/gm, '');
}
export function removeExtraSpaces(myString) {
	if (!myString) return '';
	let tempStr = myTrim(myString);
	return tempStr.replace(/(^\s+|\s+$)/g, '').replace(/&nbsp;/g, ' ').trim();
}
export function handleTableChange(current, filters, sorter, currentDataSource, fromTableChange) {
	const defaultSort = (data) => {
		data?.sort((a, b) => {
			if (sorter.order === 'ascend') {
				if (b[sorter?.field] > a[sorter?.field]) return 1;
			}
			if (sorter.order === 'descend') {
				if (a[sorter?.field] > b[sorter?.field]) return -1;
			}
			return 0;
		})
		return data
	};
	
	return {
		current: current,
		sorter: sorter,
		data: fromTableChange ? currentDataSource : defaultSort(currentDataSource || [])
	}
}

export function handleRowSelect(newSelectedRowKeys = [], selectedRowData = [], record = null, index = null, getters, setters, select = 'single', selectAll = false,rowKey = 'id') {
	let selRowKeys = newSelectedRowKeys
	let selRowData = selectedRowData
	
	if(selectAll) {
		setters?.setRowKeys(selRowKeys);
		setters?.setRowData(selRowData);
		return
	}
	if(index !== null && index > -1) {
		let currentKeys = [...getters?.rowKeys]
		let currentData = [...getters?.rowData]
		
		let keyIndex = currentData?.findIndex((it) => it?.[rowKey] === record?.[rowKey])
		if(keyIndex > -1) {
			currentKeys?.splice(keyIndex, 1)
			currentData?.splice(keyIndex, 1)
		} else {
			if(select === 'multi') {
				currentKeys.push(record?.[rowKey])
				currentData.push(record)
			} else {
				currentKeys?.splice(0, 1, record?.[rowKey])
				currentData?.splice(0, 1, record)
			}
		}
		selRowKeys = currentKeys
		selRowData = currentData
	}
  setters?.setRowKeys(selRowKeys);
	setters?.setRowData(selRowData);
}
//function to filter select option.
export const filterSelectOption = (input, option)=>{
	return option?.children?.toLowerCase()?.includes(input?.toLowerCase());
};

export function sortTwoNumbers(a,b,key,isDate = false){
	let valueA = a[key];
  let valueB = b[key];
	if (isDate) {
    valueA = valueA !== null && valueA !== undefined ? new Date(valueA) : -Infinity;
    valueB = valueB !== null && valueB !== undefined ? new Date(valueB) : -Infinity;
  } else {
    valueA = valueA !== null && valueA !== undefined ? parseInt(valueA) : -Infinity;
    valueB = valueB !== null && valueB !== undefined ? parseInt(valueB) : -Infinity;
  }

  if (isNaN(valueA) && isNaN(valueB)) {
    return 0;
  }
  if (isNaN(valueA)) {
    return 1;
  }
  if (isNaN(valueB)) {
    return -1;
  }
  return valueA - valueB;
}
export const compareArrays = (arr1, arr2) => {
	if (arr1.length !== arr2.length) {
		return false;
	}
	for (let i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) {
			return false;
		}
	}
	return true;
};

export const disableSpaceBar = (event) => {
	if (event.key === ' ') {
		event.preventDefault();
	}
};

export const checkEmptySpace = (event, formItem) => {
	if(!formItem) disableSpaceBar(event);
}

export const surveyRedirectRoute = (record = null, student = true, surveyIdentifier = 'survey_id', login = '1') => {
	let survey_id = record?.[surveyIdentifier] || ''
  let survey_type = record?.survey_type || ''
  let role = student ? 's' : 'f'
	let loggedIn = login === '1' ? true : false
  let encoded = encodeURI(`survey_id=${survey_id}&survey_type=${survey_type}&role=${role}&login=${loggedIn}`)

  if(student)
    return `/answer-survey-student?${encoded}`
  return `/answer-survey?${encoded}`
}

//generate columns to display advisees
export const generateAdviseeMenteeListColumn = (list, field, setter) => {
	if(list?.length === 0) {
		setter([])
		return
	}
	let numColumns = 4
	let nameList = list?.map((item) => item?.[field])
	const studentsPerColumn = Math.ceil(nameList.length / numColumns);
	const columns = [];
	for (let i = 0; i < numColumns; i++) {
		columns.push(
			<Col span={24 / numColumns} key={i}>
				<ul>
					{
						nameList
						.slice(i * studentsPerColumn, (i + 1) * studentsPerColumn)
						.map((student, index) => (
							<li><b>{student}</b></li>
						))
					}
				</ul>
			</Col>
		);
	}
	setter(columns)
}

export const notificationHandler = (notif, navigateFn, props) => {
	const getCurrentRoute = () => {
		//GENERATE CURRENT URL ROUTE
		let currentRouteSplit = window.location.href?.split('/')
		let currentRoute = currentRouteSplit[currentRouteSplit?.length - 1]
		return `/${currentRoute}`
	}
	if (notif?.notification_url === "student-mentor-request") {
		props?.setNotificationId(notif?.notification_id)
		props?.setMentorRequestModalOpen(true)
	} else if(notif?.notification_text?.toLowerCase()?.includes('survey')) {
		navigateFn(surveyRedirectRoute(notif, props?.isStudent, 'id_for_notification'));
	} else if(notif?.notification_text?.toLowerCase()?.includes('advisory')) {
		if(props?.isStudent) {
			navigateFn(`/${notif?.notification_url}`)
			return
		}
		//GENERATE REDIRECT URL
		let splitUrl = notif?.notification_url?.split('/')
		let currentRoute = getCurrentRoute()
		let urlProps = {
			student_id: splitUrl?.[1],
			tabkey: "advising-notes",
			tab_title: constants.AdvisingNotes,
			from: constants.Notifications,
			from_route: currentRoute,
			student_name: splitUrl?.[2],
			sem_id: splitUrl?.[3],
			year_in_program: splitUrl?.[4]
		}
		navigateFn(`/${splitUrl?.[0]}`, { state: urlProps });
	} else if(notif?.notification_text?.toLowerCase()?.includes('result')) {
		let splitUrl = notif?.notification_url?.split('/')
		let currentRoute = getCurrentRoute()
		let urlProps = {
			selectedRecord: {
				course_id: splitUrl?.[1],
				id: splitUrl?.[2],
			},
			previousRoute: currentRoute,
		}
		navigateFn(`/${splitUrl?.[0]}`, { state: urlProps })
	} else {
		let url = `/${notif?.notification_url}`
		navigateFn(url);
	}
}
