import React, { useState, useEffect} from 'react';
import { Row, Col, Card, Form, Button, Table, Select, Input, InputNumber, Checkbox,message, Space, Modal,Flex, Alert } from 'antd';
import constants from '../../Constants/constants';
import { fetchStaffApi } from '../../Services/Api';
import BreadcrumbHeader from '../Component/BreadcrumbHeader';
import { getModal, closeModal, CustomSortIcon, getNextAndPreviousButtons, showTotalItems, performSearch, HighlightedText, handleRowSelect } from '../../GlobalFunctions/GlobalFunctions';
import { CheckOutlined } from '@ant-design/icons';
import { COOKIE, getCookie } from '../../Services/Cookie';
import { useLocation } from 'react-router-dom';

const statusTypes = {
	'1': constants.Dismissed,
	'2': constants.Probation,
	'3': constants.LeftProgram,
	'4': constants.Break,
	'5': constants.InActive,
	'6': constants.SlpMajor,
	'10':constants.Graduated,
}
const yearTypes = {
	'1': constants.Freshman,
	'2': constants.Sophomore,
	'3': constants.Junior,
	'4': constants.Senior,
	'5': constants.Graduate,
	'6': constants.PartTime,
}
const StudentGpa = () => {
	const props = useLocation()
	const [form] = Form.useForm();
	const [gpaData,setGpaData] = useState([]);
	const [studentType,setStudentType] = useState(0);
	const [studentYear, setStudentYear] = useState(0);
	const [studentStatus,setStudentStatus] = useState(0);
	const [studentSort, setStudentSort] = useState(0);
	const [searchText, setSearchText] = useState('');
	const [filteredGpaData, setFilteredGpaData] = useState([]);
	const [searchFilteredGpaData, setSearchFilteredGpaData] = useState([]);
	const [promotedStudentsData, setPromotedStudentsData] = useState([]);
	const [payloadValues, setPayloadValues] = useState([]);
	const [showPromotedStudentModal,setShowPromotedStudentModal] = useState(false);
	const [selectedRow, setSelectedRow] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [showConfirm,setShowConfirm] = useState(false);
	const [gpaValues, setGpaValues] = useState({});
	const [statusTypeList,setStatusTypeList] = useState([]);
	const [isPartTime, setIsPartTime] = useState(false);
	const [rowData, setRowData] = useState([]);
	const [rowKeys, setRowKeys] = useState([]);
	const [showGraduateConfirmationModal, setShowGraduateConfirmationModal] = useState(false);
	const [isDisable, setIsDisable] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [graduateStudentsList,setGraduateStudentsList] = useState([]);
	// let confirmBtnCheck;
	const breadcrumbItems = [
		{
			label: constants.StudentGPA,
			active: true
		}
	];
	useEffect(()=>{
		setIsPartTime(props?.state?.partTime || false);
		getStudentGpaDetails(props?.state?.partTime || false);
	}, [studentType, studentYear, studentStatus, studentSort])
	
	function checkValue(x) {
		return (x === '' || x === null);
	}
	const getStudentGpaDetails = (partTime = false) => {
		setGpaData([]);
		setFilteredGpaData([]);
		let payload = {
			year_in_program: studentYear,
			type: studentType,
			status: studentStatus,
			sort: studentSort,
			is_part_time: partTime ? 1 : 0,
		};

		fetchStaffApi(`/get-student-gpa-list`, 'POST', payload).then((res) => {
			if (res.data?.length > 0) {
				const initialGpaValues = res.data.reduce((acc, curr) => {
					acc[curr.id] = parseFloat(curr.gpa).toFixed(2);
					return acc;
				}, {});

				let data = res?.data?.map((item) => { return {
					...item,
					showError: false,
					hasChanged:false,
					toGraduate:item?.is_graduated&&item?.is_graduated==="1"?true:false,
					isGraduateCheckboxValue:false,
					statusLabel: statusTypes[item?.status]
				}})
				setGpaData(data);
				setFilteredGpaData(data);
				setSearchFilteredGpaData(data);
				setGpaValues(initialGpaValues); // Initialize gpaValues state
				// confirmBtnCheck = res.data.map(({ gpa }) => gpa);
				// setShowConfirm(confirmBtnCheck.some(checkValue));
				checkShowConfirmButton(res?.data)
			} else {
				setGpaData([]);
				setFilteredGpaData([]);
				setSearchFilteredGpaData([]);
			}
		});
	};
	const getStudentType = (value)=>{
		clearSearchInput();
		if (value === undefined || value === null || value === '') {
			setStudentType(0);
		} else {
			setStudentType(value);
		}
	}
	const getStudentyearType = (value)=>{
		clearSearchInput();
		if (value === undefined || value === null || value === '') {
			setStudentYear(0);
		} else {
			setStudentYear(value);
		}
	}
	const getStudentStatus = (value)=>{
		clearSearchInput();
		if (value === undefined || value === null || value === '') {
			setStudentStatus(0);
		} else {
			setStudentStatus(value);
		}
	}
	const getSortValue = (value) => {
		clearSearchInput();
		setStudentSort(value);
	}

	const onSearch = (event) => {
		const search = event.target.value;
		setSearchText(search);
		const searchFieldNames = [
			'year_in_program_text',
			'name',
			'gpa',
			'statusLabel',
			'semester_description'
		];
		let searchedValue = performSearch(search, searchFilteredGpaData, searchFieldNames);
		setFilteredGpaData(searchedValue);
	};
	useEffect(() => {
		setStatusTypeList(constants.StatusList.filter((each) =>each?.value !== '4' && each?.value !== '10' && each?.value !== '3' && each?.value !=='9'));
	}, []);

	const handleGpaChange = (field, recordIndex, value, record) => {
		//UPDATE GPA VALUE AT PARTICULAR INDEX AND UPDATE STATE
		const updateRecord = (records) => {
			return records.map(eachRecord => {
				if (eachRecord.id === record.id) {
					return { ...eachRecord, gpa: value};
				}
				return eachRecord;
			});
		};
		const mainGpaData = updateRecord([...gpaData]);
		const gpaD = updateRecord([...filteredGpaData]);
		// Set error flag for GPA value
		gpaD[recordIndex].showError = value > 4;
		if(value){gpaD[recordIndex].hasChanged = true;}
		// Update state with new GPA data
		if(graduateStudentsList.length>0){
			let indexPos = graduateStudentsList.findIndex((item)=>item?.id === record?.id)
			if(indexPos!==-1){
				graduateStudentsList[indexPos].gpa=value;
			}
			setGraduateStudentsList(graduateStudentsList);
		}
		setGpaData(mainGpaData);
		setFilteredGpaData(gpaD);
		const recordData = {
			id: record.id,
			gpa: value,
			faculty_id: getCookie(COOKIE.UserId),
			student_semester_id: record.student_semester_id,
			gpascores_id: record?.gpascores_id,
			is_part_time: isPartTime
		};
		// Update the rowkey onchange of value
		if(isPartTime){
			let rowDataList = [...rowData];
			let rowKeysList = [...rowKeys];
			if(record?.id&&!record.isGraduateCheckboxValue){
				let indexPos = rowDataList.findIndex((item) => item?.id === record?.id);
				if(value){
					if(indexPos===-1){
						record.gpa = value;
						record.hasChanged = true;
						rowDataList.push(record);
						rowKeysList.push(record?.id);
					}else{
						rowDataList[indexPos].hasChanged=true;
						rowDataList[indexPos].gpa = value;
					}
				}else{
					rowKeysList.splice(indexPos,1);
					rowDataList.splice(indexPos,1);
				}
				setRowData(rowDataList);
				setRowKeys(rowKeysList);
				if(rowKeys.includes(record.id)){
				}else{
					onSelectChange(null, null, record, recordIndex);
				}
				setRefresh(!refresh);
			}
		}else{
			// checkShowConfirmButton(gpaD)
		}
		// Update the payload values
		setPayloadValues((prevUpdateData) => {
			const existingRecordIndex = prevUpdateData.findIndex((r) => r.id === record.id);
			if(existingRecordIndex !== -1){
				const updatedData = [...prevUpdateData];
				updatedData[existingRecordIndex] = recordData;
				return updatedData;
			}else{
				return [...prevUpdateData, recordData];
			}
		});

	};

	const checkShowConfirmButton = (data) => {
		let filteredByStatus = data?.filter(it => it?.status === '6' || it?.status === '2');
		let allPromoted = filteredByStatus?.every((it) => it.is_promoted === 't')
		if(studentStatus === 0 && studentYear === 0) {
			let allGPAEntered = filteredByStatus?.filter(item => item?.gpa === null || item?.gpa === '')?.length === 0;
			if(allGPAEntered && !allPromoted) {
				setShowConfirm(true);
				return
			}
		}
		setShowConfirm(false);
	}

	const textColorBasedOnStudentStatus = (record) => {
		return record?.status === '1' || record?.status === '2' ? 'text_danger' : 'text_dark';
	};
	const highlightedValue = (record)=>{
		return record?.status === '1' || record?.status === '2'  || (record?.gpa < record?.gpa_level )? 'text_danger' : 'text_dark';
	}
	const gpaTableColumns = [
		{
			title: constants.Name,
			key: '1',
			width: '20%',
			showSorterTooltip: {placement: 'bottom'},
			sorter: (a, b) => a.name.localeCompare(b.name),
			render: (record) => {
				const studentName = record?.name || '';
				return <span className={textColorBasedOnStudentStatus(record)}><HighlightedText text={studentName} highlight={searchText} /></span>
			},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			hidden:false
		},
		{
			title: constants.YearProgram,
			key: '2',
			width: '20%',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => yearTypes[a.year_in_program].localeCompare(yearTypes[b.year_in_program]),
			render: (record) => {
				return <span className={textColorBasedOnStudentStatus(record)}><HighlightedText text={record?.year_in_program_text} highlight={searchText} /></span>
			},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			hidden:false
		},
		{
			title: constants.Gpa,
			key: '3',
			dataIndex:'gpa',
			width: '20%',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => a.gpa - b.gpa,
			render: (text, record, recordIndex) => (
				<div className={highlightedValue(record)}>
					<InputNumber 
						type="number" 
						step={0.01}
						changeOnWheel={false}
						className='w-75 academicSpecInput hide-handle'
					 	min={0}
						disabled={record?.status==="10"}
						onChange={(value) => handleGpaChange('gpa', recordIndex, value, record)}
						onWheel={(e) => e.target.blur()}
						value={record.gpa}
						changeOnBlur={false}
						onPressEnter={(e) => handleGpaChange('gpa', recordIndex, e.target.value, record)}
					/>
					{
						record.showError && <div className="text-xs mt-2 text-red-500">{constants.EnterGPAErrorMessage}</div>
					}
				</div>
			),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			hidden:false
		},
		{
			title:constants.Status,
			key: '4',
			width: '13%',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => statusTypes[a.status].localeCompare(statusTypes[b.status]),
			render: (record) => {
				const statusLabel = statusTypes[record?.status] || '';
				const highlightedStatus = statusLabel.toLowerCase().includes(searchText.toLowerCase()) ? statusLabel.replace(new RegExp(`(${searchText})`, 'gi'), '<span class="highlight">$1</span>') : statusLabel;
				return (
					<span className={textColorBasedOnStudentStatus(record)} dangerouslySetInnerHTML={{ __html: highlightedStatus }}></span>
				);
			},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			hidden:false
		},
		{
			title: constants.Semester,
			key: '5',
			dataIndex:'semester_description',
			width: '20%',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => a.localeCompare(b.semester),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			hidden:isPartTime ? false : true
		},
		{
			title: constants.Graduate,
			key: '6',
			width: '7%',
			render: (_,record, recordIndex) => (
				record?.toGraduate&&
					<div className='text-center'>
						<Checkbox
							checked={record.isGraduateCheckboxValue}
							onChange={(e)=>onChangeGraduate(e,record,recordIndex)}/>
					</div>
			),
			hidden:isPartTime ? false : true
		}
	].filter((val)=> !val?.hidden);

	const onChangeGraduate = (e,record,recordIndex) =>{
		//UPDATE GPA VALUE AT PARTICULAR INDEX AND UPDATE STATE
		const updateRecord = (records) => {
			return records.map(eachRecord => {
				if (eachRecord.id === record.id) {
					return { ...eachRecord};
				}
				return eachRecord;
			});
		};
		let toGraduateList = [...graduateStudentsList];
		const mainGpaData = updateRecord([...gpaData]);
		const gpaD = updateRecord([...filteredGpaData]);
		// Set error flag for GPA value
		// Update state with new GPA data
		let indexPos = toGraduateList.findIndex((item) => item?.id === record?.id);
		if(e.target.checked){
			record.isGraduateCheckboxValue=true;
			gpaD[recordIndex].isGraduateCheckboxValue = true;
			if(indexPos===-1){
				toGraduateList.push(record);
			}
		}else{
			record.isGraduateCheckboxValue=false;
			gpaD[recordIndex].isGraduateCheckboxValue = false;
			if(indexPos!==-1){
				toGraduateList.splice(indexPos,1);
			}
		}
		setGraduateStudentsList(toGraduateList);
		setGpaData(mainGpaData);
		setFilteredGpaData(gpaD);
	}
	const submitGpaValues = (graduated=null)=>{
		if(payloadValues.length>0 || graduated !== null){
			setIsDisable(true);
			let url,payload;
			if( graduated !== null){
				url = '/graduate-student';
				let students = [];
				students = rowData?.filter((val)=>val?.gpa!==null).map((val)=>{
					if(val?.gpa!==null){
						return {					
							id: val?.id,
							student_semester_id: val?.student_semester_id,
							faculty_id:getCookie(COOKIE.UserId),
							is_part_time:isPartTime,
							gpascores_id:val?.gpascores_id,
							gpa:val?.gpa,
						}
					}
				})
				payload = {
					changed_by:getCookie(COOKIE.UserId),
					students:students
				}
			}else{
				url = '/save-student-gpa';
				payload = payloadValues
			}
			// Reset payloadValues state and fetch student GPA details
			window.scrollTo(0, 0);
			setPayloadValues([]);
			fetchStaffApi(url, 'POST', payload).then((res) => {
				if (res.code === '200') {
					setIsDisable(false);
					message.success(constants.DataSaved);
					setPayloadValues([]);
					setRowKeys([]);
					setRowData([]);
					setGraduateStudentsList([]);
					getStudentGpaDetails(isPartTime);
					if(graduated!==null){
						setShowGraduateConfirmationModal(false);
					}
				} else {
					setIsDisable(false);
					message.error(res?.message);
					// setPayloadValues([]);
					// setRowKeys([]);
					// setRowData([]);
					if(graduated!==null){
						setShowGraduateConfirmationModal(false);
					}
				}
			}).catch(() => {
				message.error(constants.ErrorMessage);
				setIsDisable(false);
				// setPayloadValues([]);
				// setRowKeys([]);
				// setRowData([]);
				if(graduated!==null){
					setShowGraduateConfirmationModal(false);
				}
			});
		}

	}

	const preventFormSubmission = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
		}
	};
	
	const promoteSelectedStudents = ()=>{
		let payload = null;
		payload={
			id: selectedRowKeys,
		}
		fetchStaffApi(`/promote-students`, 'POST', payload).then((res) => {
			if(res?.code === '200'){
				closeModal(setShowPromotedStudentModal);
				message.success(constants.DataSaved);
				getStudentGpaDetails(isPartTime);
			}else{
				closeModal(setShowPromotedStudentModal);
				message.error(constants.Error);
			}
		});
	}
	
	const promotedStudentsTableColumns = [
		{ 
			key:1,
			title:constants.Name,
			dataIndex:'name',
			align:'left',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => { return a.name.localeCompare(b.name)},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
		{
			key: 2,
			title: constants.YearProgram,
			align: 'left',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => yearTypes[a?.year_in_program].localeCompare(yearTypes[b?.year_in_program]),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (record) => yearTypes[record?.year_in_program] || '',
		}
	]
	const onSelectAllChange = (e) => {
		if (e.target.checked) {
			const allRowKeys = promotedStudentsData.filter((each) => each?.is_promoted !== 't').map(({id})=>id) // Assuming 'key' is the unique data field
			setSelectedRowKeys(allRowKeys);
		} else {
			setSelectedRowKeys([]);
		}
	};
	const tableRowSelection = {
		selectedRowKeys: selectedRowKeys,
		onChange: (selectedRowKeys,selectedRows) => {
			setSelectedRow(selectedRows);
			setSelectedRowKeys(selectedRowKeys);
		},
		preserveSelectedRowKeys: true,
		columnTitle: (
			<Checkbox
			className='customCheckAll'
			checked={selectedRowKeys.length === promotedStudentsData.filter((each)=>each?.is_promoted !== 't').length}
			onChange={onSelectAllChange}
			/>
		),
		type: 'checkbox',
		renderCell: (checked, record, index, originNode) => {
			return record?.is_promoted === 't' ? <CheckOutlined className='text-success fw-bold' /> : originNode
		}
	};
	const displayModal = ()=>{
		return(
			<>
				<Table
					className='records-table data_table mentorTable'
					bordered
					dataSource={promotedStudentsData}
					columns={promotedStudentsTableColumns}
					size="small"
					rowKey={(record) =>record?.id}
					rowSelection={{...tableRowSelection}}
					pagination={{
						className:"!mb-0",
						pageSize: constants.TablePageSize,
						showTotal: (total, range) => showTotalItems(total, range, promotedStudentsData),
						itemRender: getNextAndPreviousButtons,
					}}
					scroll={{ x:'768px',y: 'calc(100vh - 333px)' }}
				/>
				<div className="flex justify-end mt-2">
					<Button disabled={selectedRowKeys.length === 0} className="text-capitalize btn-info mb-3" onClick={promoteSelectedStudents}>{constants.Promote}</Button>
				</div>
			</>
		)
	}
	const getPromotedStudents = () => {
		setShowPromotedStudentModal(true);
		fetchStaffApi(`/get-promoted-students`, 'GET').then((res) => {
			if (res?.data) {
				setPromotedStudentsData(res?.data);
				const selectedKeys = res?.data
					.filter(row => row?.is_promoted !== 't')
					.map(row => row?.id);
				const selectedRecords = res?.data
					.filter(row => row?.is_promoted !== 't')
					.map(row => row);
				setSelectedRowKeys(selectedKeys);
			} else {
				setPromotedStudentsData([]);
			}
		});
	}
	const clearKeys = () => {
	}

	const clearSearchInput = () => {
		form.setFieldsValue({searchBar:""})
		setSearchText('');
	};

	const onChangePartTime = (e) => {
		setRowKeys([]);
		setIsPartTime(e.target.checked);
		getStudentGpaDetails(e.target.checked)
	};

	const onSelectChange = (newSelectedRowKeys = [], selectedRowData = [], record = null, index = null, selectAll = false) => {
		handleRowSelect(
			newSelectedRowKeys, //KEYS FROM ON SELECT CHANGE
			selectedRowData, //RECORDS FROM ON SELECT CHANGE
			record, //CURRENT SELECTED RECORD
			index, //CURRENT SELECTED RECORD INDEX
			{ rowKeys: rowKeys, rowData: rowData }, //GET STATES FOR KEYS AND RECORDS
			{ setRowKeys: setRowKeys, setRowData: setRowData }, //SET STATES FOR KEYS AND RECORDS
			'multi', //SELECTION TYPE
			selectAll
		)
	};

	const rowSelection = {
		fixed: 'left',
		selectedRowKeys: rowKeys,
		onSelectAll: (selected, selectedRows, changeRows) => {
			let data = selected ? filteredGpaData : []
			onSelectChange(
				data?.map((item) => item.id),
				data,
				null,
				null,
				true
			)
		},
		onSelect: (record, selected, selectedRows) => onSelectChange(
			selectedRows?.map((item) => item.id), //RETRIEVE ONLY IDs
			selectedRows, //ARRAY OF RECORDS WHICH ARE SELECTED
			record, //CURRENT SELECTED RECORD
			filteredGpaData?.findIndex((item) => item.id === record.id) //INDEX OF THE CURRENTLY SELECTED RECORD FROM TABLE DATA
		),
	};

	const handleGraduatedOrPromoted = (type) => {
		//type 1 is graduated and 2 is promoted
		if(type==="1"){
			if(graduateStudentsList?.length>0&&graduateStudentsList?.some(item=>item?.gpa===null)){
				message.error(constants.EnterGPA);
			}else{
				setShowGraduateConfirmationModal(true);
			}
		}else{
			if(rowData&&rowData?.length>1&&rowData?.some(item=>(item?.gpa===null))){
				message.error(constants.EnterGPA);
			}else{
				submitGpaValues();
			}
		}
	};
	const rowClassName = (record) => {
		if (record.status === "10") return 'advisor-row';
			return '';
	};
	return (
		<div>
			<BreadcrumbHeader pageTitle={constants.StudentGPA} breadcrumbItems={breadcrumbItems} />
			<Row>
				<Col span={24}>
					<Card className="container-border-custom position-relative">
						<Form form={form} /* onFinish={submitGpaValues} */ onKeyDown={preventFormSubmission}>
						<Row>
							<Col span={24}>
								<div className='flex flex-wrap justify-between items-center gap-3'>
									<Space wrap>
										<Form.Item name="studentYear" className='w-36 !mb-2.5'>
											<Select
												placeholder='Year' 
												maxTagCount='responsive'
												onChange={getStudentyearType}
												disabled={form.getFieldValue('studentType') === 2}
												allowClear
											>
												{constants.StudentYearOptions.map((option, index) => (
													<Select.Option key={option} value={index+1}>
														{option}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
										<Form.Item name="studentStatus" className='w-36 !mb-2.5'>
												<Select
													placeholder={constants.Status} 
													maxTagCount='responsive'
													onChange={getStudentStatus}
													allowClear
											>
													{statusTypeList.map((option, index) => (
													<Select.Option key={option} value={Number(option.value)}>
														{option.label}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
										<Form.Item label='Sort By' name="studentSort" className='!mb-2.5'>
											<Select
												placeholder='Sort By'
												maxTagCount='responsive'
												onChange={getSortValue}
												defaultValue={0}
												className='w-36'
												value={studentSort} 
											>
												<Select.Option value={0}>{constants.FirstName}</Select.Option>
												<Select.Option value={1}>{constants.LastName}</Select.Option>
											</Select>
										</Form.Item>
									</Space>
									<div>
											<Space wrap>
												<Form.Item name="partTime" className='!mb-2.5'>
													<Checkbox checked={isPartTime} onChange={onChangePartTime} >{constants.PartTime}</Checkbox>
												</Form.Item>
												<Form.Item name="searchBar" className='!mb-2.5'>
													<Input placeholder={constants.Search} className='w-full lg:w-48' onChange={onSearch}></Input>
												</Form.Item>
											</Space>
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Form.Item name="gpaValues">
									{isPartTime ?
										<Table
											className='records-table data_table'
											rowSelection={{
												...rowSelection,
												getCheckboxProps: (record) => ({
													disabled: record?.status === '10',
												}),
											}}
											bordered
											dataSource={filteredGpaData}
											columns={gpaTableColumns}
											size="small"
											pagination={{
												className:"!mb-0",
												pageSize: constants.TablePageSize,
												showTotal: (total, range) => showTotalItems(total, range, filteredGpaData),
												itemRender: getNextAndPreviousButtons,
											}}
											scroll={{ x:'768px', y: 'calc(100vh - 333px)' }}
											rowKey={(record) => record.id}
											rowClassName={rowClassName}
										/> :
										<Table
											className='records-table data_table'
											bordered
											dataSource={filteredGpaData}
											columns={gpaTableColumns}
											size="small"
											pagination={{
												className:"!mb-0",
												pageSize: constants.TablePageSize,
												showTotal: (total, range) => showTotalItems(total, range, filteredGpaData),
												itemRender: getNextAndPreviousButtons,
											}}
											scroll={{ x:'768px', y: 'calc(100vh - 333px)' }}
											rowKey={(record) => record.id}
										/>
									}
								</Form.Item>
									<div className='flex justify-end'>
										{isPartTime ?
											<Space>
												<Button
													type='primary'
													size="middle"
													className="text-capitalize"
													onClick={()=>handleGraduatedOrPromoted("2")}
													disabled={(isDisable||rowData.length===0||(rowData&&rowData.length===1&&rowData?.some(item=>item?.hasChanged===false))||(rowData&&rowData?.length>1&&rowData?.every(item=>(item?.hasChanged===false))))}
												>{constants.Promote}</Button>
												<Button
													type='primary'
													size="middle"
													className="text-capitalize"
													onClick={()=>handleGraduatedOrPromoted("1")}
													disabled={graduateStudentsList&&graduateStudentsList?.length===0}
												>{constants.Graduated}</Button>
											</Space>:
											<Space>
												<Form.Item className='!mb-0'>
													<Button type='primary' disabled={payloadValues&&payloadValues.length>0?false:true} onClick={() => submitGpaValues()} className='text-sm'>{constants.Save}</Button>
												</Form.Item>
												{
													showConfirm &&
													<Form.Item className='!mb-0'>
														<Button onClick={getPromotedStudents} type='primary' className='text-sm text-light'>{constants.Confirm}</Button>
													</Form.Item>
												}
											</Space>
										}
									</div>
							</Col>
						</Row>
							{showPromotedStudentModal && getModal(constants.PromoteStudents, displayModal(), showPromotedStudentModal, closeModal, setShowPromotedStudentModal, () => clearKeys())}
							<Modal
								title={constants.Confirmation}
								open={showGraduateConfirmationModal}
								footer={null}
								cancelText={constants.Close}
								onCancel={() => setShowGraduateConfirmationModal(false)}
								className='!top-5'
							>
								<strong>{constants.GraduateStudentConfirmationMessage}</strong>
								<Flex justify='flex-end'>
									<Space className='mb-2'>
										<Button
											type='primary'
											size="middle"
											className="text-capitalize"
											onClick={() => setShowGraduateConfirmationModal(false)}
											danger
										>
											{constants.Close}
										</Button>
										<Button
											type='primary'
											size="middle"
											className="text-capitalize"
											onClick={()=>submitGpaValues("graduate-student")}
											disabled={isDisable}
										>
											{constants.Confirm}
										</Button>
									</Space>
								</Flex>
							</Modal>
						</Form>
					</Card>
				</Col>
			</Row>
		</div>
	)
}
export default StudentGpa
