import React, { useState, useEffect } from 'react'
import BreadcrumbHeader from './Component/BreadcrumbHeader'
import { Button, Form, Card, Row, Col, Flex, Select, Input, DatePicker, Table, Tooltip, Modal, message, Alert, Spin } from 'antd'
import constants from '../Constants/constants'
import { InfoCircleTwoTone } from '@ant-design/icons'
import { fetchStaffApi } from '../Services/Api'
import dayjs from 'dayjs'
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import { performSearch, HighlightedText, CustomSortIcon, getNextAndPreviousButtons, showTotalItems, handleRowSelect } from '../GlobalFunctions/GlobalFunctions'
import { Link, useNavigate } from 'react-router-dom'
const { Option } = Select;

const CreateSemester = () => {
	const breadcrumbItems = [{ label: constants.CreateSemester, href: '/', active: true },]
	const [form] = Form.useForm();
	const [form2] = Form.useForm();
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [selectedRow, setSelectedRow] = useState()
	const [showTable, setShowTable] = useState(false);
	const [showSelectStudentModal, setShowSelectStudentModal] = useState(false);
	const [semesterDetails, setSemesterDetails] = useState(null);
	const [modalVisible, setModalVisible] = useState(false);
	const [confirmSemesterEnd, setConfirmSemesterEnd] = useState(false)
	const [selectSemester, setSelectSemester] = useState(constants.SemType);
	const [semesterType, setSemesterType] = useState(null)
	const [studentDetails, setStudentDetails] = useState([])
	const [studentInfo, setStudentInfo] = useState([])
	const [confirmSemEndVisible, setConfirmSemEndVisible] = useState(false)
	const [selectedStudentIds, setSelectedStudentIds] = useState()
	const [selectedStudentName, setSelectedStudentName] = useState()
	const [form2Values, setForm2Values] = useState({})
	const [formValues, setFormValues] = useState({})
	const [fallModalVisible, setFallModalVisible] = useState(false);
	const [onSaveLoader, setOnSaveLoader] = useState(false);
	const [showSelectStudentButton, setShowSelectStudentButton] = useState(false);
	const [currentSemName, setCurrentSemName] = useState()
	const [searchValue, setSearchValue] = useState("");
	const [filterTableData, setFilterTableData] = useState([]);
	const [rowData, setRowData] = useState([])
	const [partTimeStudentsList, setPartTimeStudentsList] = useState([])
	const currentDate = dayjs().format("MMM D, YYYY");
	const navigate = useNavigate();

	useEffect(() => {
		// Fetch data from API
		fetchSemesterDetails();
	}, []);

	const fetchSemesterDetails = () => {
		fetchStaffApi('/get-semester-details', 'GET').then((res) => {
			if (res?.code === "200") {
				let Data = res?.data
				setSemesterDetails(Data);
				setStudentInfo(Data.student_details)
				setConfirmSemesterEnd(Data?.latest_semester?.confirm_semester_end)
				setSemesterType(Data?.latest_semester?.semester_type)

				if (!Data?.latest_semester?.semester_type) {
					fetchVisibleSemesterDetails()
				}

				let currentSem = constants.SemType.find(semester => semester.semester_id === Data?.latest_semester?.semester_type);
				setCurrentSemName(currentSem);

				if (Data) {
					if (Data.latest_semester) {
						form2.setFieldsValue({
							semester_type: Data?.latest_semester?.semester_type,
							semester_start_date: Data?.latest_semester.semester_start_date ? dayjs(Data.latest_semester.semester_start_date) : null,
							semester_end_date: Data?.latest_semester.semester_end_date ? dayjs(Data.latest_semester.semester_end_date) : null,
							class_end_date: Data?.latest_semester.class_end_date ? dayjs(Data.latest_semester.class_end_date) : null,
							grade_entry_date: Data?.latest_semester.grade_entry_date ? dayjs(Data.latest_semester.grade_entry_date) : null,
						});
					}
					if (Data.fall_semester) {
						form.setFieldsValue({
							semester_type_fall: Data.fall_semester.semester_type,
							semester_start_date: Data?.fall_semester?.semester_start_date ? dayjs(Data.fall_semester.semester_start_date) : null,
							semester_end_date: Data.fall_semester?.semester_end_date ? dayjs(Data.fall_semester.semester_end_date) : null,
							class_end_date: Data.fall_semester?.class_end_date ? dayjs(Data.fall_semester.class_end_date) : null,
							grade_entry_date: Data.fall_semester?.grade_entry_date ? dayjs(Data.fall_semester.grade_entry_date) : null,
						});
					}
				}
			} else {
				setSemesterDetails([]);
				setConfirmSemesterEnd(false)
				fetchVisibleSemesterDetails()
			}
		});
	};
	const fetchVisibleSemesterDetails = () => {
		fetchStaffApi('/get-visible-semesters', 'GET').then((res) => {
			if (res?.code === "200") {
				const visibleSemesters = res?.data
				const newValueType = selectSemester.filter(record => visibleSemesters.includes(record.semester_id));
				setSelectSemester(newValueType)
			}
		})
	}
	const rowSelection = {
		type: 'checkbox',
		selectedRowKeys,
		columnWidth: '5%',
		// onChange: (newSelectedRowKeys, selectedRow) => {
		// 	setSelectedRowKeys(newSelectedRowKeys);
		// 	setSelectedRow(selectedRow);
		// }
		onSelectAll: (selected, selectedRows, changeRows) => {
			let data = selected ? studentDetails:[]
			onSelectChange(
			data?.map((item) => item.id),
			data,
			null,
			null,
			true
		)},
		onSelect: (record, selected, selectedRows) => onSelectChange( 
			// selectedRows?.map((item) => item.id), //RETRIEVE ONLY IDs
			// selectedRows, //ARRAY OF RECORDS WHICH ARE SELECTED
			null,
			null,
			record, //CURRENT SELECTED RECORD
			studentDetails?.findIndex((item) => item.id === record.id) //INDEX OF THE CURRENTLY SELECTED RECORD FROM TABLE DATA
		),
	};
	const onSelectChange = (newSelectedRowKeys = [], selectedRowData = [], record = null, index = null, selectAll = false) => {
		handleRowSelect(
			newSelectedRowKeys, //KEYS FROM ON SELECT CHANGE
			selectedRowData, //RECORDS FROM ON SELECT CHANGE
			record, //CURRENT SELECTED RECORD
			index, //CURRENT SELECTED RECORD INDEX
			{ rowKeys: selectedRowKeys, rowData: rowData }, //GET STATES FOR KEYS AND RECORDS
			{ setRowKeys: setSelectedRowKeys, setRowData: setRowData }, //SET STATES FOR KEYS AND RECORDS
			'multi', //SELECTION TYPE
			selectAll,
		)
		setSelectedRow(selectedRowData);

  };
	const onSearch = (event) => {
		const search = event.target.value;
		setSearchValue(search)
		const searchFieldNames = [
			"student_name"
		];
		let searchedValue = performSearch(search, filterTableData, searchFieldNames);
		setStudentDetails(searchedValue);
	}
	const handleSemesterTypeChange = (value) => {
		if (value === "3" || value === "4") {
			setShowSelectStudentButton(true);

		} else {

			setShowSelectStudentButton(false);
		}
	};
	const studentColumns = [
		{
			title: 'Name',
			dataIndex: "student_name",
			width: "60%",
			sorter: (a, b) => a.student_name.localeCompare(b.student_name),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (student_name) => <HighlightedText text={student_name} highlight={searchValue} />
		},
		{
			title: 'Year in Program',
			dataIndex: "year_in_program_text",
			width: "40%",
			sorter: (a, b) => a.year_in_program_text.localeCompare(b.year_in_program_text),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		}
	];
	const renderButtons = () => {
		if (confirmSemesterEnd) {
			return <Button type="primary" className='action-btn' onClick={() => handleConfirmSemesterEnd()}>Confirm Semester End</Button>;
		} else if (!semesterDetails?.latest_semester) {
			return <Button type="primary" className='action-btn' onClick={() => handleSave('Create')}>Create</Button>;
		}
		return null
	};
	const hoverTable = () => {
		setShowTable(true);
	};
	const handleSelectStudent = () => {
		setShowSelectStudentModal(true);
		fetchStaffApi('/get-semester-students/0', 'GET').then((res) => {
			if (res?.code === "200") {
				setStudentDetails(res?.data?.length > 0 ? res.data : []);
				setFilterTableData(res?.data?.length > 0 ? res.data : [])
			}
			else {
				message.error(res?.message)
			}
		})
	}
	const handleCloseSelectStudentModal = () => {
		setShowSelectStudentModal(false)
		setSelectedRow()
		setSelectedRowKeys([])
	}
	const handleConfirmSemesterEnd = () => {
		form2.validateFields().then(() => {
			fetchStaffApi('/check-semester-end', 'GET').then((res) => {
				if (res?.code === "200") {
					checkSemesterEnd(res)
				} else message.error(res?.message);
			});
		}).catch((err) => {
			console.log(err)
		})
	}
	const checkSemesterEnd = (result) => {	
		switch (parseInt(result?.data?.status)) {
			case 0:
				message.error(result?.message)
				break;
			case 2:
				let students = result?.data?.part_time_students
				setPartTimeStudentsList(students?.length > 0 ? students : [])
				setConfirmSemEndVisible(true)
				break;
			case 3:
				message.error(result?.message);
				setTimeout(() => navigate('/student-gpa'), 500);
				break;
			case 4:
				message.error(result?.message);
				setTimeout(() => navigate('/student-gpa'), 500);
				break;
			default:
				break;
		}
	}
	const handleSave = (buttonType) => {
		form2.validateFields().then(() => {
			const values = form2.getFieldsValue();
			const semesterSetType = selectSemester.find(semester => semester.semester_id === values.semester_type);
			const test = values.truncate_tables ? values.truncate_tables : constants.DefaultTruncateTable;
	
			if (buttonType === "Save") {
				setOnSaveLoader(true)
				const payload = {
					"semester_type": values.semester_type,
					"semester_start_date": values.semester_start_date.format("MMM D, YYYY"),
					"semester_end_date": values.semester_end_date.format("MMM D, YYYY"),
					"class_end_date": values.class_end_date.format("MMM D, YYYY"),
					"grade_entry_date": values.grade_entry_date.format("MMM D, YYYY"),
				}
				const payloadWithIds = { ...payload, "semester_student": selectedStudentIds }
	
				fetchStaffApi('/create-semester', 'POST', values.semester_type === '1' || values.semester_type === '2' ? payload : payloadWithIds).then((res) => {
					if (res?.code !== "200") {
						message.error(res?.message);
					}
					message.success(res?.message)
					form2.resetFields()
					fetchSemesterDetails()
					setOnSaveLoader(false)
				}).catch((error) => {
					setOnSaveLoader(false)
				});
			} else {
				let tables = [];
				constants.Semesters.map((record) => {
					test?.map((item) =>
						record.value === item ?
							tables.push(record.label) : ""
					)
				})
				const testString = tables?.join(", ");
				const obj = {
					"semester": semesterSetType.semester_name,
					"semester_start_date": values.semester_start_date.format("MMM D, YYYY"),
					"semester_end_date": values.semester_end_date.format("MMM D, YYYY"),
					"class_end_date": values.class_end_date.format("MMM D, YYYY"),
					"grade_entry_date": values.grade_entry_date.format("MMM D, YYYY"),
					"truncate_tables": testString,
					"selected_Student": selectedStudentName,
				}
				setForm2Values(obj);
				if(values.semester_type === "1" || values.semester_type === "2" || selectedStudentName)
					setModalVisible(true)
				else message.error("Select students")
			}
		}).catch((err) => {
			setOnSaveLoader(false)
		})
	};
	const handleConfirmCreate = () => {
		handleSave(constants.Save)
		setModalVisible(false)
		fetchSemesterDetails()
	}
	const confirmSemEnd = () => {
		const values = form2.getFieldsValue();
		const test = values.truncate_tables ? values.truncate_tables : constants.DefaultTruncateTable;
		const testString = test?.join(", ");
		const payload = {
			"truncate_tables": testString
		}
		fetchStaffApi('/confirm-semester-end', 'POST', payload).then((res) => {
			if (res?.code === "200") {
				closeSemesterEndModal('cancel')
				message.success(constants.successMessage5)
				form2.resetFields()
				fetchSemesterDetails()
			} else {
				message.error(res?.message)
			}
		})
	}
	const handleYearTypeChange = (e) => {
		const value = e === undefined ? 0 : e
		fetchStaffApi(`/get-semester-students/${value}`, 'GET').then((res) => {
			if(res?.code === "200") {
				setStudentDetails(res?.data?.length > 0 ? res.data : [])
				return
			}
			setStudentDetails([])
		}).catch((err) => {
			console.log(err);
		})
	}
	const SaveSelectedStudent = () => {
		const ids = selectedRow.map(row => row.id).join(',');
		const name = selectedRow.map(row => row.student_name).join(',');
		// Store the concatenated IDs in the state variable
		setSelectedStudentIds(ids)
		setSelectedStudentName(name)
		setShowSelectStudentModal(false)
	}
	const handleMaxTagPlaceholder = (omittedValues) => (
		<Tooltip title={omittedValues.map(({ label }) => label).join(', ')}>
			<span>+ {omittedValues.length}</span>
		</Tooltip>
	);
	const layout1 = () => {
		return (
			<Card className="mb-3 container-border-custom dashboard-card">
				<Form className='md:px-5' form={form2} layout="vertical">
					{semesterType === "3" || semesterType === "4" ?
						<div >
							<div className='info-margin-adjust'>
								<b>{constants.CurrentSem} : {currentSemName?.semester_name} </b>
								<Button className='view-student-style' onClick={hoverTable} type="link" block> view summer students</Button>
							</div>
						</div> : ""
					}
					{showSelectStudentButton || semesterType === "3" || semesterType === "4" ?
						<Alert
							message={constants.Info}
							type="info"
							showIcon
							action={
								<Button type="primary" className='btnStyle action-btn' 
								disabled={semesterType === "3" || semesterType === "4"}
									onClick={handleSelectStudent}>{constants.SelectStudent}</Button>
							}
							className='mb-3'
						/>
						: null
					}
					<Row gutter={[40, 5]}>
						<Col xs={24} lg={8}>
							<Form.Item name="semester_type" className='form-text-deco' label="Select Semester:"
								rules={[{
									required: true,
									message: constants.FieldRequired
								}]}>
								<Select allowClear onChange={handleSemesterTypeChange}
									disabled={semesterDetails?.latest_semester?.semester_type}
									popupClassName="select-dropdown"
									placeholder="-- Select --" >
									{selectSemester?.map(option => {
										return <Option key={option.semester_id} value={option.semester_id}>{option.semester_name}</Option>
									})}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={24} lg={8}>
							<Form.Item name="semester_start_date" className='form-text-deco' label="Semester Start Date: "
								rules={[{ required: true, message: constants.FieldRequired }]}>
								<DatePicker
									disabled={semesterDetails?.latest_semester?.semester_start_date &&
										dayjs(semesterDetails.latest_semester.semester_start_date).isBefore(currentDate)}
									className='datePickerWidth'
									format="MMM D, YYYY"
								/>
							</Form.Item>
						</Col>
						<Col xs={24} lg={8}>
							<Form.Item name="semester_end_date" className='form-text-deco' label="Semester End Date: "
								rules={[
									{ required: true, message: 'Semester end date is required' },
									({ getFieldValue }) => ({
										validator(_, value) {
											const gradeEntryDate = getFieldValue('grade_entry_date');
											if (value && gradeEntryDate && value < gradeEntryDate) {
												return Promise.reject('Please enter a date after Grade Entry Date');
											}
											return Promise.resolve();
										},
									}),
								]}
							>
								<DatePicker className='datePickerWidth'
									format="MMM D, YYYY"
									disabledDate={(current) => current && current < dayjs().startOf('day')} />
							</Form.Item>
						</Col>

						<Col xs={24} lg={8} >
							<Form.Item className='form-text-deco ' name="truncate_tables" label="Select Tables to Clear Data:">

								<Select
									maxTagCount="responsive"
									mode="multiple"
									placeholder="Please select"
									defaultValue={["notification_details", "activitylogger", "advisor_signup"]}
									maxTagPlaceholder={handleMaxTagPlaceholder}
								>
									{constants.Semesters.map((option) => (
										<Option key={option.value} value={option.value} disabled={option.isdisable}>
											{option.label}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={24} lg={8} className='mb-0'>
							<Form.Item name="class_end_date" className='form-text-deco' label="Class End Date:"
								rules={[
									{ required: true, message: 'Class End Date is required' },
									({ getFieldValue }) => ({
										validator(_, value) {
											const semesterStartDate = getFieldValue('semester_start_date');
											const gradeEntryDate = getFieldValue('grade_entry_date');
											if (value && semesterStartDate && value < semesterStartDate) {
												return Promise.reject('Please enter a date after Semester Start Date');
											} else if (value && gradeEntryDate && value > gradeEntryDate) {
												return Promise.reject('Please enter a date before Grade Entry Date');
											}
											return Promise.resolve();
										},
									}),
								]}

							>
								<DatePicker
									className='datePickerWidth'
									format="MMM D, YYYY"
									disabledDate={(current) => current && current < dayjs().startOf('day')} />
							</Form.Item>
						</Col>
						<Col xs={24} lg={8} className='mb-0'>
							<Form.Item name="grade_entry_date" className='form-text-deco' label="Grade Entry Date"
								rules={[
									{ required: true, message: 'Grade entry date is required' },
									({ getFieldValue }) => ({
										validator(_, value) {
											const classEndDate = getFieldValue('class_end_date');
											const semEndDate = getFieldValue('semester_end_date');
											if (value && classEndDate && value < classEndDate) {
												return Promise.reject('Please enter a date after Class End Date');
											} else if (value && semEndDate && value > semEndDate) {
												return Promise.reject('Please enter a date before Sem End Date');
											}
											return Promise.resolve();
										},
									}),
								]}
							>
								<DatePicker
									className='datePickerWidth'
									format="MMM D, YYYY"
									disabledDate={(current) => current && current < dayjs().startOf('day')} />
							</Form.Item>
						</Col>
					</Row>

					<Flex gap="small" align="flex-end" className='action-button-container'>
						<Flex gap="small" wrap="wrap">
							<Link to="/dashboard">
								<Button type="primary" className='action-btn' danger >{constants.Cancel}</Button>
							</Link>
							<Button hidden={!semesterDetails?.latest_semester} type="primary" className='action-btn' onClick={() => handleSave("Save")}>Save</Button>
							{renderButtons()}
						</Flex>
					</Flex>
				</Form>
			</Card>
		)
	}
	const renderFallButtons = () => {
		let button = null;

		if (semesterDetails?.fall_semester && !semesterDetails?.latest_semester) {
			button = <Button type="primary" className='action-btn' onClick={() => handleSetCurrentSem('Set to Current Semester')}>Set to Current Semester</Button>;
		} else if (!semesterDetails?.fall_semester) {
			button = <Button type="primary" className='action-btn' onClick={() => handleFallSave('Create')}>Create</Button>;
		}

		return button;
	};
	const handleFallSave = (buttonType) => {
		const values = form.getFieldsValue();

		if (buttonType === "Save") {
			const payload = {
				"semester_type_fall": values.semester_type_fall,
				"semester_start_date": values.semester_start_date.format("MMM D, YYYY"),
				"semester_end_date": values.semester_end_date.format("MMM D, YYYY"),
				"class_end_date": values.class_end_date.format("MMM D, YYYY"),
				"grade_entry_date": values.grade_entry_date.format("MMM D, YYYY"),
			}

			fetchStaffApi('/create-semester', 'POST', payload).then((res) => {
				if (res?.code !== "200") {
					message.error(constants.Err4);
				}
				message.success(res?.message)
				form.resetFields()
				fetchSemesterDetails()
			});
		} else {
			const obj = {
				"semester": "Fall",
				"semester_start_date": values.semester_start_date.format("MMM D, YYYY"),
				"semester_end_date": values.semester_end_date.format("MMM D, YYYY"),
				"class_end_date": values.class_end_date.format("MMM D, YYYY"),
				"grade_entry_date": values.grade_entry_date.format("MMM D, YYYY"),

			}
			setFormValues(obj);
			setFallModalVisible(true);
		}
	}
	const handleSetCurrentSem = () => {
		setSelectSemester(constants.SemType)
		const values = form.getFieldValue()
		const payload = {
			"semester_type_fall": values.semester_type_fall
		}
		fetchStaffApi('/set-current-semester', 'POST', payload).then((res) => {
			message.success(constants.successMessage2)
			fetchSemesterDetails()
		})
	}
	const handleConfirmFallCreate = () => {
		handleFallSave("Save")
		setFallModalVisible(false)
		message.success(constants.successMessage3)
		fetchSemesterDetails()
	}
	const layout2 = () => {
		return (
			<Card className="mb-3 container-border-custom dashboard-card">
				<Form className='md:px-5' form={form} layout="vertical" >
					<Alert
						message={constants.fallInfo}
						type="info"
						showIcon
						className='mb-3'
					/>
					<Row gutter={[40, 5]}>
						<Col xs={24} lg={8}>
							<Form.Item className='form-text-deco' name="semester_type_fall" label="Select Semester:"
								rules={[{
									required: true,
									message: constants.FieldRequired
								}]}>
								<Select popupClassName="select-dropdown"
									disabled={semesterDetails?.fall_semester?.semester_type ? true : false}
									placeholder="-- Select --">
									{constants.FallType?.map(option => {
										return <Option key={option.semester_id} value={option.semester_id}>{option.semester_name}</Option>
									})}
									{/* <Option key="1" value="1">Fall</Option> */}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={24} lg={8}>
							<Form.Item className='form-text-deco' name="semester_start_date" label="Semester Start Date: "
								rules={[{ required: true, message: constants.FieldRequired }]}>
								<DatePicker
									disabled={semesterDetails?.fall_semester?.semester_start_date &&
										dayjs(semesterDetails?.fall_semester?.semester_start_date).isBefore(dayjs(currentDate))}
									className='datePickerWidth'
									format="MMM D, YYYY"
								//    disabledDate={(current) => current && current < dayjs().startOf('day')}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} lg={8}>
							<Form.Item className='form-text-deco' name="semester_end_date" label="Semester End Date: "
								rules={[
									{ required: true, message: 'Semester end date is required' },
									({ getFieldValue }) => ({
										validator(_, value) {
											const gradeEntryDate = getFieldValue('grade_entry_date');
											if (value && gradeEntryDate && value < gradeEntryDate) {
												return Promise.reject('Please enter a date after Grade Entry Date');
											}
											return Promise.resolve();
										},
									}),
								]}
							>
								<DatePicker className='datePickerWidth'
									format="MMM D, YYYY"
									disabledDate={(current) => current && current < dayjs().startOf('day')} />
							</Form.Item>
						</Col>
						<Col xs={24} lg={8}>
							<Form.Item className='form-text-deco' name="class_end_date" label="Class End Date:"
								rules={[
									{ required: true, message: 'Class End Date is required' },
									({ getFieldValue }) => ({
										validator(_, value) {
											const semesterStartDate = getFieldValue('semester_start_date');
											const gradeEntryDate = getFieldValue('grade_entry_date');
											if (value && semesterStartDate && value < semesterStartDate) {
												return Promise.reject('Please enter a date after Semester Start Date');
											} else if (value && gradeEntryDate && value > gradeEntryDate) {
												return Promise.reject('Please enter a date before Grade Entry Date');
											}
											return Promise.resolve();
										},
									}),
								]}
							>
								<DatePicker
									disabled={semesterDetails?.fall_semester?.class_end_date &&
										dayjs(semesterDetails?.fall_semester?.class_end_date).isBefore(currentDate)}
									className='datePickerWidth'
									format="MMM D, YYYY"
									disabledDate={(current) => current && current < dayjs().startOf('day')} />
							</Form.Item>
						</Col>
						<Col xs={24} lg={8}>
							<Form.Item className='form-text-deco' name="grade_entry_date" label="Grade Entry Date"
								rules={[
									{ required: true, message: 'Grade entry date is required' },
									({ getFieldValue }) => ({
										validator(_, value) {
											const classEndDate = getFieldValue('class_end_date');
											const semEndDate = getFieldValue('semester_end_date');
											if (value && classEndDate && value < classEndDate) {
												return Promise.reject('Please enter a date after Class End Date');
											} else if (value && semEndDate && value > semEndDate) {
												return Promise.reject('Please enter a date before Sem End Date');
											}
											return Promise.resolve();
										},
									}),
								]}

							>
								<DatePicker
									disabled={semesterDetails?.fall_semester?.grade_entry_date &&
										dayjs(semesterDetails?.fall_semester?.grade_entry_date).isBefore(currentDate)}
									className='datePickerWidth'
									format="MMM D, YYYY"
									disabledDate={(current) => current && current < dayjs().startOf('day')} />
							</Form.Item>
						</Col>
					</Row>

					<Flex gap="small" align="flex-end" className='action-button-container'>
						<Flex gap="small" wrap="wrap">
							<Link to="/dashboard">
								<Button type="primary" className='action-btn' danger>{constants.Cancel}</Button>
							</Link>
							<Button hidden={!semesterDetails?.fall_semester} type="primary" className='action-btn' onClick={() => handleFallSave("Save")}>Save</Button>
							{renderFallButtons()}
						</Flex>
					</Flex>
				</Form>
			</Card>
		)
	}

	const closeSemesterEndModal = (action) => {
		if(action === 'confirm') {
			setTimeout(() => navigate('/student-gpa', { state: { partTime: true } }), 500); 
			return
		}
		setPartTimeStudentsList([]);
		setConfirmSemEndVisible(false)
	}
	return (
		<Spin tip={constants.PleaseWait} spinning={onSaveLoader}>
			<div>
				<BreadcrumbHeader pageTitle="Create Semester" breadcrumbItems={breadcrumbItems} />
				{layout1()}
				{semesterDetails &&
					(semesterType === '3' || semesterType === '4' || semesterDetails?.fall_semester) && (
						<>
							{layout2()}
						</>
					)}
				<Modal title="Selected Students" open={showTable} onCancel={() => setShowTable(false)} width={600} footer={null}>
					<div className='mt-3 mb-2' >
						<Table columns={studentColumns} dataSource={studentInfo} bordered size="small"
							className="records-table data_table"
							pagination={{
								pageSize: constants.TablePageSize,
								showTotal: (total, range) => showTotalItems(total, range, studentInfo),
								itemRender: getNextAndPreviousButtons,
							}}
							scroll={{ y: '50vh' }} />
					</div>
				</Modal>
				<Modal title="Confirm" open={modalVisible} footer={null} onCancel={() => setModalVisible(false)}>
					<h6><strong>Are you sure you want to create a semester?</strong></h6>
					<Row >
						<Col xs={24} lg={8}>
							<ul className='no-decoration-list'>
								<li>Semester </li>
								<li>Semester Start Date </li>
								<li>Semester End Date</li>
								<li>Class End Date </li>
								<li>Grade Entry Date </li>
								<li>Tables to Clear </li>
								{form2Values?.semester === 'Summer Session I' || form2Values?.semester === 'Summer Session II' ? (
									<li>Selected Students</li>
								) : null}
							</ul>
						</Col>
						<Col span={16}>
							<ul className='no-decoration-list'>
								<li>{form2Values?.semester}</li>
								<li>{form2Values?.semester_start_date}</li>
								<li>{form2Values?.semester_end_date}</li>
								<li>{form2Values?.class_end_date}</li>
								<li>{form2Values?.grade_entry_date}</li>
								<li>{form2Values?.truncate_tables}</li>
								{form2Values?.semester === 'Summer Session I' || form2Values?.semester === 'Summer Session II' ? (
									<li>{form2Values?.selected_Student}</li>
								) : null}
							</ul>
						</Col>
					</Row>
					<Flex gap="small" align="flex-end" className='action-button-container mt-2'>
						<Flex gap="small" wrap="wrap">
							<Button type="primary" className='action-btn' danger
								onClick={() => setModalVisible(false)}>{constants.Cancel}</Button>
							<Button type="primary" className='action-btn' onClick={handleConfirmCreate}>Confirm</Button>
						</Flex>
					</Flex>
				</Modal>
				<Modal title={constants.Confirm} open={fallModalVisible} footer={null} onCancel={() => setFallModalVisible(false)}>
					<h6><strong>Are you sure you want to create a semester?</strong> </h6>
					<Row>
						<Col xs={24} lg={8}>
							<ul className='no-decoration-list'>
								<li>Semester: </li>
								<li>Semester Start Date: </li>
								<li>Semester End Date:</li>
								<li>Class End Date: </li>
								<li>Grade Entry Date: </li>
							</ul>
						</Col >
						<Col span={16}>
							<ul className='no-decoration-list'>
								<li>{formValues?.semester}</li>
								<li>{formValues?.semester_start_date}</li>
								<li>{formValues?.semester_end_date}</li>
								<li>{formValues?.class_end_date}</li>
								<li>{formValues?.grade_entry_date}</li>
							</ul>
						</Col>
					</Row>
					<Flex gap="small" align="flex-end" className='action-button-container'>
						<Flex gap="small" wrap="wrap">
							<Button type="primary" className='action-btn' danger
								onClick={() => setFallModalVisible(false)}>{constants.Cancel}</Button>
							<Button type="primary" className='action-btn' onClick={handleConfirmFallCreate}>Confirm</Button>
						</Flex>
					</Flex>
				</Modal>
				<Modal 
					maskClosable={false}
					title={constants.Confirm} 
					open={confirmSemEndVisible} 
					footer={null} 
					onCancel={() => closeSemesterEndModal('cancel')}
					width={700}
				>
					{
						partTimeStudentsList?.length > 0 && (
							<>
								<strong>GPAs have not been assigned to the following part time students:-</strong>
								
								<Row gutter={[8, 8]} className='my-3'>
									{partTimeStudentsList?.map((stud, index) => (
										<Col key={index}>
											<strong>{stud?.year}: </strong><>{stud?.student_names?.join(', ')}</><br />
										</Col>
									))}
								</Row>
							</>
						)
					}
					<strong>Are you sure to confirm semester end?</strong>
					<Flex gap="small" align="flex-end" className='action-button-container'>
						<Flex gap="small" wrap="wrap">
							<Button 
								type="primary" 
								className='action-btn' 
								danger={partTimeStudentsList?.length === 0}
								onClick={() => closeSemesterEndModal('confirm')}
							>
								{setPartTimeStudentsList?.length > 0 ? 
								constants.GoToGPA : constants.Cancel}
							</Button>
							<Button type="primary" className='action-btn' onClick={confirmSemEnd}>Confirm</Button>
						</Flex>
					</Flex>
				</Modal>
				<Modal className='!top-5' title="Select Students" open={showSelectStudentModal} onCancel={handleCloseSelectStudentModal} width={650} footer={null}>
					<Row gutter={[0, 0]}>
						<Col span={6}>
							<Select className='w-36' allowClear onChange={handleYearTypeChange} placeholder="Select" popupClassName="select-dropdown" defaultActiveFirstOption
								showSearch
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								filterSort={(optionA, optionB) =>
									optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
								}>
								{constants.YearOptions.map((option) => (
									<Option key={option.Year_id} value={option.Year_id}>{option.year_name}</Option>))}
							</Select>
						</Col>
						<Col span={6} offset={12}>
							<Input placeholder="Search" onChange={onSearch} />
						</Col>
					</Row>
					<div className='mt-2' >
						<Table
							className="records-table data_table studentTable"
							rowSelection={rowSelection}
							columns={studentColumns}
							dataSource={studentDetails}
							rowKey={(record) => record.id}
							bordered size="small"
							pagination={{
								pageSize: constants.TablePageSize,
								showTotal: (total, range) => showTotalItems(total, range, studentDetails),
								itemRender: getNextAndPreviousButtons,
							}}
							onRow={(record, rowIndex) => {
								return {
									onClick: () => onSelectChange(null, null, record, rowIndex),
								}
							}}
							scroll={{ y: '50vh' }}
						/>
						<Col className='d-flex justify-content-end'>
							<Button className=" adjust-margin text-capitalize btn-info" onClick={SaveSelectedStudent}>Save</Button>
						</Col>
					</div>
				</Modal>
			</div>
		</Spin>
	)
}
export default CreateSemester